import React, { useRef } from "react"
import { useEffect, useState } from "react"
import { Link, useNavigate, useParams } from "react-router-dom"
import { useTimer } from "react-timer-hook"
import { ToastContainer, toast } from "react-toastify"
import { t } from "i18next"
import { useFeatureFlagEnabled } from "posthog-js/react"
import { useAppDispatch, useAppSelector } from "../../redux/store"
import { clearQrId, getEvent } from "../../redux/slices/events/eventsSlice"
import {
  getContactsOnEventMobile,
  setCurrentPage,
} from "../../redux/slices/contacts/contactsSlices"
import Preloader from "../../components/utils/Preloader/Preloader"
import {
  IconAlertOctagon,
  IconArrowLeft,
  IconArrowUpFat,
  IconContactDelete,
  IconContactsChange,
  IconLocation,
  IconQR,
  IconSearch,
} from "../../components/utils/Icons/CustomIcons"
import GuestsSearch from "../../components/Event/GuestsSearch"
import GuestsList from "../../components/Event/GuestsList"
import Paginator from "../../components/utils/Pagination/Pagination"
import GuestsFilter from "../../components/Event/GuestFilter"
import GuestsPopup from "../../components/Event/GuestsPopup"
import ActionsPopup from "../../components/Event/ActionsPopup"
import TestModal from "../../components/Event/TestModal/TestModal"
import EnterCodeModal from "../../components/Event/EnterCodeModal/EnterCodeModal"
import VerifyModal from "../../components/Event/VerifyModal/VerifyModal"
import AddGuest from "../../components/Event/AddGuest/AddGuest"
import EditGuest from "../../components/Event/EditGuest/EditGuest"
import AddInvites from "../../components/Event/AddInvites/AddInvites"
import HoverTooltip from "../../components/Event/HoverTooltip"
import { iFile } from "../../components/Contacts/types"
import SendModal from "../../components/Event/SendModal/SendModal"
import SendQRModal from "../../components/Event/SendQRModal/SendQRModal"
import SendInviteModal from "../../components/Event/SendInviteModal/SendInviteModal"
import DeleteEventModal from "../../components/Event/DeleteEventModal/DeleteEventModal"
import ImportGuest from "../../components/Event/ImportGuest/ImportGuest"
import DownloadAppModal from "@/components/Event/DownloadAppModal/DownloadAppModal"
import FrontImages from "@/components/Event/Images/FrontImages"
import "react-toastify/dist/ReactToastify.css"

const EventPage = () => {
  const { event_id } = useParams()
  const isShowEventImagesScreen = useFeatureFlagEnabled(
    "show_event_images_screen",
  )
  const navigate = useNavigate()
  const { isLoading } = useAppSelector((state) => state.events)
  const { getCredits } = useAppSelector((state) => state.auth)
  const event = useAppSelector((state) => state.events.currentEvent)
  const { current_page, total_pages, limit } = useAppSelector(
    (state) => state.contacts,
  )
  const dispatch = useAppDispatch()

  const [show, setShow] = useState<boolean>(false)
  const [showSendQR, setShowSendQR] = useState<boolean>(false)
  const [showImport, setShowImport] = useState<boolean>(false)
  const [addShow, setAddShow] = useState(false)
  const [editShow, setEditShow] = useState(false)
  const [changeGuest, setChangeGuest] = useState({})
  const [showVerifyModal, setShowVerifyModal] = useState<boolean>(false)
  const [showNextStep, setShowNextStep] = useState<boolean>(false)
  const [showTestModal, setShowTestModal] = useState<boolean>(false)
  const [showModalDelete, setShowModalDelete] = useState<boolean>(false)
  const [showModalSendInvite, setShowModalSendInvite] = useState<boolean>(false)
  const [showModalAddInvites, setShowModalAddInvites] = useState<boolean>(false)
  const [showModalDownloadApp, setShowModalDownloadApp] =
    useState<boolean>(false)

  const [checked, setChecked] = useState([])
  const [checkedFilter, setCheckedFilter] = useState("")
  const [allGuestsCheked, setAllGuestsChecked] = useState([])
  const [phone, setPhone] = useState()
  const [validation, setValidation] = useState([])
  const [width, setWidth] = useState<number>(window.innerWidth)
  const [fileImport, setFileImport] = useState<iFile | null>(null)
  const [imgDimensions, setImgDimensions] = useState({ width: 0, height: 0 })
  const [styles, setStyles] = useState({})
  const [showModalSendInvitations, setShowModalSendInvitations] =
    useState<boolean>(false)
  const time = new Date()
  time.setSeconds(time.getSeconds() + 180)
  const { seconds, minutes, restart } = useTimer({
    expiryTimestamp: time,
  })
  const [newGuest, setNewGuest] = useState({
    event_id: event_id,
    first_name: "",
    last_name: "",
    suffix: "",
    phone: "",
    email: "",
    quantity_members: 0,
    deleted_at: null,
  })
  const [filter, setFilter] = useState({
    sort: "",
    order: "",
    searchValue: "",
    statusNumber: "",
    reminderNumber: "",
    id: Number(event_id),
  })

  const notify = (text: string, color: boolean) => {
    color
      ? toast.error(text, {
          position: "top-right",
          autoClose: 3000,
        })
      : toast.success(text, {
          position: "top-right",
          autoClose: 3000,
        })
  }

  useEffect(() => {
    dispatch(getEvent(Number(event_id)))
  }, [dispatch, event_id])

  const onPageChanged = (page: number) => {
    dispatch(setCurrentPage(page))
  }

  const addPopupClose = () => {
    setAddShow(false)
  }

  const addInvitesClose = () => {
    setShowModalAddInvites(false)
  }

  const downloadAppModalClose = () => {
    setShowModalDownloadApp(false)
  }

  const importPopupClose = () => {
    setShowImport(false)
  }

  const editPopupOpen = (guest: any) => {
    setChangeGuest(guest)
    setEditShow(true)
  }

  useEffect(() => {
    const updateWidowSizes = () => {
      setWidth(window.innerWidth)
    }
    window.addEventListener("resize", updateWidowSizes)
    return () => {
      window.removeEventListener("resize", updateWidowSizes)
    }
  }, [])

  const handleImageLoad = (e: any) => {
    const { width, height } = e.target
    setImgDimensions({ width, height })
  }

  useEffect(() => {
    if (imgDimensions.width > 0 && imgDimensions.height > 0) {
      if (imgDimensions.height > imgDimensions.width) {
        setStyles({ height: "100%", width: "auto" })
      } else {
        setStyles({ width: "100%", height: "auto" })
      }
    }
  }, [imgDimensions])

  //test inv

  return (
    <>
      {isLoading && <Preloader />}
      {event && (
        <div className="event-page">
          <div key={event.event_id} className="container">
            <div className="event-page__link">
              <Link
                className="d-flex align-items-center event-page__link-container"
                to={"/events"}
              >
                <IconArrowLeft />
                <span className="event-page__link-text">
                  {t("event.backToAllEvents")}
                </span>
              </Link>
              <ToastContainer
                style={{ width: "90%", maxWidth: "400px", fontSize: "18px" }}
              />
            </div>
            <div className="d-flex justify-content-between event-page__buttons">
              <h2 className="caption-two">{event.name}</h2>
              <div className="d-flex">
                <button
                  className="d-flex justify-content-center align-items-center event-page--btn"
                  onClick={() => {
                    navigate(`/event-edit/${event.event_id}`)
                    dispatch(clearQrId())
                  }}
                >
                  <IconContactsChange />
                </button>
                <button
                  className="d-flex justify-content-center align-items-center event-page--btn"
                  onClick={() => {
                    setShowModalDelete(!showModalDelete)
                  }}
                >
                  <IconContactDelete />
                </button>
              </div>
            </div>
            <div className="event-wrapper">
              <div className="event-container">
                <div className="d-flex event-image__container">
                  <div className="event-image__img">
                    <img
                      src={
                        event.pattern ? event.pattern : "/vector/no-foto.svg"
                      }
                      alt="Image"
                      style={styles}
                      className="img-fluid"
                      onLoad={handleImageLoad}
                    />
                  </div>
                  <div style={{ width: "100%" }}>
                    <div className="d-flex mb-3 event-page__header">
                      <h2 className="caption-two event-page__title">
                        {t("event.eventDetailsTitle")}
                      </h2>

                      <div className="d-flex event-page__invitation-buttons">
                        {getCredits === 0 ? (
                          width > 1279 ? (
                            <HoverTooltip
                              text={
                                "Confirm your phone number and receive 5 credits as a gift"
                              }
                              id={"t-5"}
                              style={{ color: "#0B0C0E", opacity: "1" }}
                            >
                              <button
                                className={`btn btn-simple event-page__test-btn ${
                                  getCredits === 0 && "event-page__test-confirm"
                                }`}
                                onClick={() => {
                                  setShowTestModal(!showTestModal)
                                }}
                              >
                                <IconAlertOctagon />
                                <span className="event-page__test-text">
                                  {t("event.buttons.testInvitation")}
                                </span>
                              </button>
                            </HoverTooltip>
                          ) : (
                            <div className="event-page__container-tooltip">
                              <HoverTooltip
                                style={{
                                  color: "#0B0C0E",
                                  paddingLeft: window.innerWidth < 992 && "0px",
                                  marginLeft:
                                    window.innerWidth < 992 ? "12px" : "45px",
                                  paddingRight:
                                    window.innerWidth < 992 && "0px",
                                  marginBottom:
                                    window.innerWidth > 991 ? "11px" : "15px",
                                  opacity: "1",
                                  minWidth:
                                    window.innerWidth < 992 &&
                                    "calc(100% - 32px)",
                                }}
                                text={
                                  "Confirm your phone number and receive 5 credits as a gift"
                                }
                                id={"t-5"}
                              >
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  <IconAlertOctagon />
                                </div>
                              </HoverTooltip>

                              <button
                                className={`btn btn-simple event-page__test-btn ${
                                  getCredits === 0 && "event-page__test-confirm"
                                }`}
                                onClick={() => {
                                  setShowTestModal(!showTestModal)
                                }}
                              >
                                <span className="event-page__test-text">
                                  {t("event.buttons.testInvitation")}
                                </span>
                              </button>
                            </div>
                          )
                        ) : (
                          <button
                            className="btn btn-simple event-page__test-btn"
                            onClick={() =>
                              navigate(`/${event_id}/test-the-invitation`)
                            }
                          >
                            {t("event.buttons.testInvitation")}
                          </button>
                        )}
                        <button
                          className="btn btn-dark event-page__send-btn"
                          onClick={() => {
                            setShowModalSendInvitations(
                              !showModalSendInvitations,
                            )
                          }}
                        >
                          {t("event.buttons.sendWhatsappInvitations")}
                        </button>
                      </div>
                    </div>
                    <div className="event-page__card-wrapper">
                      <div className="event-page__card-container card-container-first">
                        <div className="event-page__card">
                          <p className="event-page__card-amount">
                            {event.count_all_contacts
                              ? event.count_all_contacts
                              : "0"}
                          </p>
                          <p className="event-page__card-name">
                            {t("event.stats.all")}
                          </p>
                        </div>
                        <div className="event-page__card">
                          <p className="event-page__card-amount">
                            {event.count_contacts_going
                              ? event.count_contacts_going
                              : "0"}
                          </p>
                          <p className="event-page__card-name">
                            {t("event.stats.going")}
                          </p>
                        </div>
                        <div className="event-page__card">
                          <p className="event-page__card-amount">
                            {event.count_contacts_decline
                              ? event.count_contacts_decline
                              : "0"}
                          </p>
                          <p className="event-page__card-name">
                            {t("event.stats.decline")}
                          </p>
                        </div>
                      </div>

                      <div className="event-page__card-container card-container-second">
                        <div className="event-page__card">
                          <p className="event-page__card-amount">
                            {event.count_contacts_ignore
                              ? event.count_contacts_ignore
                              : "0"}
                          </p>
                          <p className="event-page__card-name">
                            {t("event.stats.waiting")}
                          </p>
                        </div>
                        <div className="event-page__card">
                          <p className="event-page__card-amount">
                            {event.count_contacts_scanned
                              ? event.count_contacts_scanned
                              : "0"}
                          </p>
                          <p className="event-page__card-name">
                            {t("event.stats.scanned")}
                          </p>
                        </div>
                        <div className="event-page__card">
                          <p className="event-page__card-amount">
                            {event.count_contacts_not_paid
                              ? event.count_contacts_not_paid
                              : "0"}
                          </p>
                          <p className="event-page__card-name">
                            {t("event.stats.notSentYet")}
                          </p>
                        </div>
                        <div className="event-page__card">
                          <p className="event-page__card-amount">
                            {event.count_contacts_failed
                              ? event.count_contacts_failed
                              : "0"}
                          </p>
                          <p className="event-page__card-name">
                            {t("event.stats.failed")}
                          </p>
                        </div>
                      </div>
                    </div>
                    {/* <div className="event-images">
                      <h2 style={{ marginBottom: "10px" }}>
                        {t("event.photos.eventPhotos")}
                      </h2>

                      <h6
                        style={{
                          fontWeight: "normal",
                          color: "grey",
                          display: "flex",
                          flexWrap: "wrap",
                        }}
                      >
                        {t("event.photos.InThisEventGuestsCanUpload")}

                        <span
                          style={{
                            marginLeft: "5px",
                            textDecoration: "underline",
                            textUnderlineOffset: "3px", // Adjust this value to increase/decrease the distance
                          }}
                        >
                          {" "}
                          {t("event.photos.upTo10Photos")}
                        </span>
                      </h6>
                      <div className="even-images-list-card">
                        {testPhotos &&
                          testPhotos.length > 0 &&
                          testPhotos.slice(0, 6).map((image: any) => (
                            <div
                             className=""
                            >
                              <img
                                src={image?.url}
                                width="100px"
                                height="100px"
                                alt="Image "
                              />
                            </div>
                          ))}
                      </div>
                    </div> */}
                    {/* {isShowEventImagesScreen && ( */}
                    <FrontImages event_id={Number(event_id)} />
                    {/* )}{" "} */}
                    <div className="event-page__details">
                      <div className="d-flex justify-content-between align-items-center event-page__details-header">
                        <h2 className="event-page__details-title">
                          {t("event.seeMoreDetails")}
                        </h2>

                        <IconArrowUpFat />
                      </div>
                      <div className="d-flex justify-content-between event-page__details-cards">
                        <div className="d-flex event-page__details-card">
                          <IconQR />
                          <div className="event-page__details-description">
                            <p className="event-page__details-caption">
                              {t("event.qrRenderPassword")}
                            </p>
                            <div className="d-flex align-items-center">
                              <h4 className="event-page__details-qr">
                                {event.password ? event.password : "000000"}
                              </h4>
                              <Link
                                className="ms-3 me-3 event-page__details-download"
                                onClick={() => {
                                  setShowModalDownloadApp(true)
                                }}
                                to={"#"}
                              >
                                {t("event.buttons.downloadApp")}
                              </Link>
                            </div>
                          </div>
                        </div>
                        {(event.venue !== "" ||
                          event.address.address !== "") && (
                          <div className="d-flex event-page__details-card">
                            <IconLocation />
                            <div className="event-page__details-description">
                              <p className="event-page__details-caption">
                                {t("event.location")}
                              </p>
                              {event.venue !== "" && (
                                <h4 className="mb-1 event-page__details-place">
                                  {event.venue}
                                </h4>
                              )}
                              {event.address.address !== "" && (
                                <p className="event-page__details-address">
                                  {event.address.address}
                                </p>
                              )}
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="event-guests">
                  <div className="d-flex justify-content-between mb-3">
                    {width > 991 && (
                      <p className="event-guests__title">
                        {t("event.guestsTableTitle")}
                      </p>
                    )}
                    <div className="d-flex event-guests__header">
                      {width < 992 && (
                        <p className="event-guests__title">
                          {t("event.guestsTableTitle")}
                        </p>
                      )}
                      {width < 1280 && width > 991 ? (
                        width < 1280 &&
                        width > 991 &&
                        !show && (
                          <button
                            className="d-flex justify-content-center align-items-center event-page__btn"
                            onClick={() => setShow(true)}
                          >
                            <IconSearch />
                          </button>
                        )
                      ) : (
                        <GuestsSearch filter={filter} setFilter={setFilter} />
                      )}
                      {width < 1280 && show && (
                        <GuestsSearch filter={filter} setFilter={setFilter} />
                      )}
                      <div className="event-guests__icon-buttons">
                        <GuestsFilter
                          filter={filter}
                          setFilter={setFilter}
                          checkedFilter={checkedFilter}
                          setCheckedFilter={setCheckedFilter}
                        />
                        <ActionsPopup
                          show={showModalAddInvites}
                          setShow={setShowModalAddInvites}
                          showSendQR={showSendQR}
                          setShowSendQR={setShowSendQR}
                          id={event_id}
                        />
                      </div>
                      <GuestsPopup
                        showAddGuest={addShow}
                        setShowAddGuest={setAddShow}
                        showImport={showImport}
                        setShowImport={setShowImport}
                        id={event_id}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <GuestsList
                filter={filter}
                setFilter={setFilter}
                width={width}
                limit={limit}
                checked={checked}
                setChecked={setChecked}
                allGuestsChecked={allGuestsCheked}
                setAllGuestsChecked={setAllGuestsChecked}
                editPopupOpen={editPopupOpen}
                editShow={editShow}
                show={showModalSendInvite}
                setShow={setShowModalSendInvite}
                notify={notify}
              />
            </div>
          </div>
          <DeleteEventModal
            type={"delete"}
            show={showModalDelete}
            setShow={setShowModalDelete}
            title={"Do you really want to delete this event?"}
            notify={notify}
          />
          <VerifyModal show={showVerifyModal} setShow={setShowVerifyModal} />
          <SendModal
            type={"invite"}
            show={showModalSendInvitations}
            setShow={setShowModalSendInvitations}
            title={
              "Invitations will be sent to all guests to whom it was not sent, or with a fail status"
            }
            notify={notify}
          />
          <SendInviteModal
            checked={checked}
            type={"invite"}
            setChecked={setChecked}
            setAllGuestsChecked={setAllGuestsChecked}
            show={showModalSendInvite}
            setShow={setShowModalSendInvite}
            title={"Your invitation will be sent to the selected guests"}
            notify={notify}
          />
          <AddInvites
            show={showModalAddInvites}
            handleClose={addInvitesClose}
            filter={filter}
            notify={notify}
          />
          <DownloadAppModal
            show={showModalDownloadApp}
            handleClose={downloadAppModalClose}
          />
          <SendQRModal
            type={""}
            show={showSendQR}
            setShow={setShowSendQR}
            title={"Do you really want to send undeliverable QR codes?"}
            buttonRight={""}
            // notify={notify}
          />
          <TestModal
            show={showTestModal}
            setShow={setShowTestModal}
            showNextStep={showNextStep}
            setShowNextStep={setShowNextStep}
            value={phone}
            setValue={setPhone}
            restart={restart}
            notify={notify}
          />
          <EnterCodeModal
            show={showNextStep}
            setShow={setShowNextStep}
            showVerifyModal={showVerifyModal}
            setShowVerifyModal={setShowVerifyModal}
            value={phone}
            setValue={setPhone}
            setShowTestModal={setShowTestModal}
            notify={notify}
            minutes={minutes}
            seconds={seconds}
            restart={restart}
          />
          <AddGuest
            guest={newGuest}
            setGuest={setNewGuest}
            show={addShow}
            handleClose={addPopupClose}
            validation={validation}
            setValidation={setValidation}
            filter={filter}
          />
          <EditGuest
            guest={changeGuest}
            setGuest={setChangeGuest}
            show={editShow}
            setShow={setEditShow}
            validation={validation}
            setValidation={setValidation}
            filter={filter}
            event_id={event_id}
            notify={notify}
          />
          <ImportGuest
            file={fileImport}
            setFile={setFileImport}
            show={showImport}
            handleClose={importPopupClose}
            filter={filter}
          />
        </div>
      )}
      <div className="event-paginator">
        {total_pages !== 0 && (
          <Paginator
            total_pages={total_pages}
            current_page={current_page}
            onPageChanged={onPageChanged}
          />
        )}
      </div>
      <div className="container">
        <button
          type="button"
          className="btn btnloadMore d-lg-none d-sm-flex btn btn-primary event-loadmore"
          onClick={() => {
            dispatch(
              setCurrentPage(
                current_page === total_pages ? 1 : current_page + 1,
              ),
            )
            dispatch(getContactsOnEventMobile(filter))
          }}
        >
          {t("buttons.loadMore")}
        </button>
      </div>
    </>
  )
}

export default EventPage
